<template>
  <a-row class="search-params-margin">
    <a-col :span="labelCol" v-if="showTitle">
      <div class="label-title">{{ title }}</div>
    </a-col>
    <a-col :span="showTitle ? valueCol : 24">
      <SearchUser
          ref="searchGoods"
          v-model:modelValue="inputValue"
          :roleIds="roleIds"
          :placeholder="'搜索'+title"
          @ok="onChange"
        />
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import SearchUser from '../SearchUser'

export default defineComponent({
  props: {
    value: {
      type: String
    },

    title: {
      type: String,
      default: '标题'
    },

    labelCol: {
      type: Number,
      default: 4
    },

    valueCol: {
      type: Number,
      default: 16
    },

    showTitle: {
      type: Boolean,
      default: true
    },

    validate: {
      type: Object,
      default: () => null
    },

    roleIds: {
      type: Array,
      default: () => [2, 3, 4, 5, 6, 7, 8, 9]
    }
  },
  components: { SearchUser },
  setup (props, { emit }) {
    const inputValue = ref(props.value)

    watch(props, (e) => {
      inputValue.value = e.value
    })

    const onChange = (e) => {
      emit('ok', e)
      emit('update:value', e.id)
    }

    return {
      inputValue,
      onChange
    }
  }
})
</script>
