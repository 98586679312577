<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="500"
    :loading="loading"
    @close="onClose"
    @ok="onSubmitFun"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-info :labelCol="6" :valueCol="16" title="商品名称" :value="checkData.goodsTitle" />
        <e-info :labelCol="6" :valueCol="16" title="商品库存" :value="checkData.goodsQuantity" />
        <e-info :labelCol="6" :valueCol="16" title="申请数量" :value="checkData.quantity" />
        <e-info :labelCol="6" :valueCol="16" title="商品单位" :value="checkData.goodsUnit" />
        <e-radio :labelCol="6" :valueCol="16" title="审核状态" v-model:value="checkState" :options="[{
          value: 1,
          label: '审核通过'
        }, {
          value: 2,
          label: '审核失败'
        }]" />

        <e-textarea v-if="checkState === 2" :labelCol="6" :valueCol="16" title="失败原因" v-model:value="reason" />
      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import form from '@/mixins/form'
import { GoodsOutClass } from '@/apis/goods'

const api = new GoodsOutClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  components: {},
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)

    const title = ref('')
    const reason = ref('')
    const unit = ref('')
    const checkState = ref(0)
    const checkData = ref({})

    const onClose = () => {
      emit('ok')
      loading.value = false
      visible.value = false
    }

    return {
      title,
      visible,
      loading,
      checkData,
      checkState,
      reason,
      unit,
      onClose
    }
  },

  methods: {
    open (formData) {
      this.checkData = formData
      this.title = '商品“' + formData.goodsTitle + '”出库审核'
      this.visible = true
    },

    onSubmitFun () {
      this.loading = true
      api
        .check({
          id: this.checkData.id,
          checkState: this.checkState,
          reason: this.reason
        })
        .then(() => {
          this.$message.success('入库成功')
          this.onClose()
        })
    }
  }
})
</script>

<style lang="less" scoped></style>
