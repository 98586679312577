<template>
  <div
    :class="{
      content: true,
      page: page.total > queryParams.Limit,
    }"
    ref="content"
  >
    <div class="main-content">
      <div class="search-box" ref="search">
        <a-form class="search-form">
          <a-row>
            <a-col :sm="6" :xxl="4">
              <ESearchGoods
                title="查询商品"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.GoodsId"
              />
            </a-col>
            <a-col :sm="6" :xxl="4">
              <ESearchUser
                title="审核用户"
                :labelCol="8"
                :valueCol="14"
                :roleIds="[5]"
                v-model:value="queryParams.CheckUserId"
              />
            </a-col>
            <a-col :sm="6" :xxl="4">
              <s-date-time
                title="开始日期"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.BeginTime"
              />
            </a-col>
            <a-col :sm="6" :xxl="4">
              <s-date-time
                title="结束日期"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.EndTime"
              />
            </a-col>
            <a-col :sm="6" :xxl="4">
              <s-select
                title="审核状态"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.CheckState"
                :options="$store.state.app.audios"
              />
            </a-col>
            <a-col :sm="6" :xxl="4">
              <s-input
                title="申请人"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.UserName"
              />
            </a-col>

          </a-row>
          <a-row>
            <a-col>
              <a-button type="primary" @click="getDataList(1)">查询</a-button>
              <a-button
                type="primary"
                @click="reset(() => resetFun())"
                danger
                style="margin-left: 10px"
              >
                重置
              </a-button>
              <a-button
                type="primary"
                @click="exportExcel"
                danger
                style="margin-left: 10px"
              >
                导出
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="data-list">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :loading="loading"
          :row-key="(record) => record.id"
          :scroll="{ y: height, x: width }"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              {{ (page.page - 1) * queryParams.Limit + index + 1 }}
            </template>

            <template v-if="column.key === 'createTime'">
              {{ $filters.formatDate(record.createTime) }}
            </template>

            <template v-if="column.key === 'checkState'">
              <a-tag color="cyan" v-if="record.checkState === 0">未审核</a-tag>
              <a-tag color="green" v-if="record.checkState === 1"
                >审核通过</a-tag
              >
              <a-tag color="red" v-if="record.checkState === 2">审核失败</a-tag>
            </template>

            <template v-if="column.key === 'checkUserName'">
              <span v-if="record.checkState === 0">未审核</span>
              <span v-else>{{ record.checkUserName }}</span>
            </template>

            <template v-if="column.key === 'result'">
              <span v-if="record.checkState === 0">未审核</span>
              <span v-if="record.checkState === 1">
                <div>审核通过</div>
                <div style="font-size: 12px; color: #999">
                  {{ $filters.formatDate(record.checkTime) }}
                </div>
              </span>
              <span v-if="record.checkState === 2">
                <div>{{ record.reason }}</div>
                <div style="font-size: 12px; color: #999">
                  {{ $filters.formatDate(record.checkTime) }}
                </div>
              </span>
            </template>

            <template v-if="column.key === 'action'">
              <a-tooltip v-if="record.checkState === 0">
                <template #title>出库审核</template>
                <a @click.stop="$refs.eCheck.open(record)">
                  <FileDoneOutlined />
                </a>
              </a-tooltip>
              <span v-else style="color: #999;cursor:not-allowed"><FileDoneOutlined /></span>
            </template>
          </template>
        </a-table>
      </div>
    </div>

    <div class="pagination">
      <a-pagination
        :total="page.total"
        v-model:current="page.page"
        v-model:page-size="queryParams.Limit"
        :hide-on-single-page="true"
        :show-size-changer="true"
        :page-size-options="['20', '40', '100', '200']"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
      >
        <template #itemRender="{ page, type }">
          <a v-if="type === 'prev'" class="ant-pagination-item-link">上一页</a>
          <a v-else-if="type === 'next'" class="ant-pagination-item-link">
            下一页
          </a>
          <span v-else>{{ page }}</span>
        </template>
      </a-pagination>
      <span>数据总数：{{ page.total }}</span>
    </div>
    <ECheck ref="eCheck" @ok="getDataList(1)" />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { FileDoneOutlined } from '@ant-design/icons-vue'
import { GoodsOutClass } from '@/apis/goods'
import page from '@/mixins/page'
import ECheck from '@/components/forms/storage/Check'
import ESearchGoods from '@/components/search-form/SearchGoods'
import ESearchUser from '@/components/search-form/SearchUser'

import dayjs from 'dayjs'
import fileDownload from 'js-file-download'

const api = new GoodsOutClass()
export default defineComponent({
  mixins: [page],
  components: {
    FileDoneOutlined,
    ECheck,
    ESearchGoods,
    ESearchUser
  },
  setup () {
    const loading = ref(false)
    const height = ref(500)
    const width = ref(500)

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 60
      },
      {
        title: '商品',
        key: 'goodsTitle',
        dataIndex: 'goodsTitle',
        width: 200
      },
      {
        title: '单位',
        key: 'goodsUnit',
        dataIndex: 'goodsUnit',
        width: 90
      },
      {
        title: '申请数量',
        key: 'quantity',
        dataIndex: 'quantity',
        width: 90
      },
      {
        title: '单价',
        key: 'unitPrice',
        dataIndex: 'unitPrice',
        width: 90
      },
      {
        title: '总价',
        key: 'totalPrice',
        dataIndex: 'totalPrice',
        width: 90
      },
      {
        title: '库存',
        key: 'goodsQuantity',
        dataIndex: 'goodsQuantity',
        width: 90
      },
      {
        title: '申请人',
        key: 'userName',
        dataIndex: 'userName',
        width: 100
      },
      {
        title: '申请时间',
        key: 'createTime',
        dataIndex: 'createTime',
        width: 180
      },
      {
        title: '审核状态',
        key: 'checkState',
        dataIndex: 'checkState',
        width: 90
      },
      {
        title: '审核人',
        key: 'checkUserName',
        dataIndex: 'checkUserName',
        width: 100
      },
      {
        title: '审核结果',
        key: 'result',
        dataIndex: 'result',
        width: 180
      }
    ]
    return {
      columns,
      loading,
      height,
      width
    }
  },
  created () {
    this.getDataList(1)
  },
  methods: {
    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit

      if (q.BeginTime) {
        q.BeginTime = q.BeginTime.format('YYYY-MM-DD')
      }
      if (q.EndTime) {
        q.EndTime = q.EndTime.format('YYYY-MM-DD')
      }

      api.gets(q).then((resp) => {
        this.list = resp.data
        this.page.total = resp.total
        this.loading = false
        this.getTableHeight()
      })
    },
    resetFun () {
      this.queryParams = {
        Limit: 20
      }

      this.getDataList(1)
    },

    changeStatus (planid, e) {
      api.state(planid, e).then(() => {
        this.$message.success('修改状态成功！')
        this.getDataList(this.page.page)
      })
    },

    getTableHeight () {
      const paddingBottom = this.page.total > this.queryParams.Limit ? 50 : 9
      const mainPadding = 30
      const thead = 56
      this.height =
        this.$refs.content.offsetHeight -
        this.$refs.search.offsetHeight -
        thead -
        paddingBottom -
        mainPadding

      this.width = this.$refs.search.offsetWidth - 10
    },

    exportExcel () {
      const q = { ...this.queryParams }
      q.Offset = (this.page.page - 1) * q.Limit

      if (q.BeginTime) {
        q.BeginTime = q.BeginTime.format('YYYY-MM-DD')
      }
      if (q.EndTime) {
        q.EndTime = q.EndTime.format('YYYY-MM-DD')
      }

      api
        .exportExcel(q)
        .then((resp) => {
          fileDownload(
            resp,
            '出库记录报表' + dayjs().format('YYYYMMDDHHmmss') + '.xlsx'
          )
        })
    }
  }
})
</script>

<style lang="less" scoped></style>
